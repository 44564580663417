<template>
  <el-dialog
    v-model="visible"
    :title="specRowMsg.code ? (isEdit ? '修改商品' : '详情') : '新增商品'"
    width="70%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="120px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="关联物料" v-if="specRowMsg.attribute == 1">
        <el-select-v2
          v-model="specRowMsg.materielCode"
          :options="materialOptions"
          placeholder="请输入物料编码或物料名称关键词"
          filterable
          clearable
          @change="handleChangeMateria"
          style="width: 90%"
        />
      </el-form-item>
      <el-form-item label="单品名称" prop="name">
        <el-input v-model="specRowMsg.name" style="width:90%" placeholder="请填写商品名称" />
      </el-form-item>
      <el-form-item label="单品图片" prop="pic">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage2"
        >
          <img v-if="specRowMsg.pic" :src="specRowMsg.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="单品规格">
        <el-input v-model="specRowMsg.spec" style="width:90%" placeholder="请填写规格" />
      </el-form-item>
      <el-form-item label="指导价(元)" prop="price2" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <div style="display: flex;">
          <el-input v-model="specRowMsg.price1" style="width: 30%; margin-right: 10px;" placeholder="支持2位小数" />～
          <el-input v-model="specRowMsg.price2" style="width: 30%; margin-left: 10px;" placeholder="支持2位小数" />
        </div>
      </el-form-item>
      <el-form-item label="最低毛利率(%)" prop="minimum" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-input v-model="specRowMsg.minimum" style="width:90%" placeholder="支持2位小数" />
      </el-form-item>
      <el-form-item label="最小单位" prop="unit" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-select v-model="specRowMsg.unit" placeholder="请选择最小单位" filterable>
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="起售数量" prop="miniNumber">
        <el-input-number
          v-model="specRowMsg.miniNumber"
          style="width:90%"
          :precision="0"
          :min="1"
          :max="100"
          :controls="false"
          placeholder="请输入起售数量"
        />
      </el-form-item>
      <el-form-item label="下发现存门店">
        <FilterShop :storeData="specRowMsg.storeCodeList" @changeStore="changeStore" />
      </el-form-item>

      <el-form-item label="下发将来门店" prop="isSales">
        <el-radio-group v-model="specRowMsg.isSales">
          <el-radio :label="1">自动开启售卖</el-radio>
          <el-cascader
            ref="casRef"
            v-model="specRowMsg.isSalesRegion"
            :options="form.shopLists"
            clearable
            :show-all-levels="false"
            collapse-tags
            collapse-tags-tooltip
            filterable
            :props="areaProps"
            style="margin-right:10px"
          ></el-cascader>
          <el-radio :label="0">禁止自动售卖</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否计入库存" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 3">
        <el-switch v-model="specRowMsg.isStock" @change="changeStock" :disabled="specRowMsg.code" />
      </el-form-item>
      <el-form-item label="是否设为推荐" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-switch v-model="specRowMsg.isRecommend" />
      </el-form-item>
      <el-form-item label="是否计入低消" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-switch v-model="specRowMsg.isMinConsume" />
      </el-form-item>
      <el-form-item label="投放渠道" prop="channel" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-checkbox-group v-model="specRowMsg.channel">
          <el-checkbox :label="item.name" size="large" v-for="item in channelList">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="售卖状态" prop="isShelves" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 2">
        <el-radio-group v-model="specRowMsg.isShelves">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架(不论是否有库存，均下架停止售卖)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供货状态" prop="isSupply" v-if="specRowMsg.attribute == 1 || specRowMsg.attribute == 3">
        <el-radio-group v-model="specRowMsg.isSupply">
          <el-radio :label="1">正常供货</el-radio>
          <el-radio :label="0">停止供货(现有库存，售完为止)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="产品介绍" prop="description">
        <el-input v-model="specRowMsg.description" placeholder="请输入产品介绍" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="specRowMsg.remarks" placeholder="请输入备注" />
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import commonApi from '@/api'
import FilterShop from '../../component/filter-shop.vue'
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, toRaw, onMounted, nextTick } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    isEdit: Boolean,
    channelList: Array,
  },
  components: {
    FilterShop,
  },
  setup(props, ctx) {
    const form = reactive({
      loadText: '正在火速加载数据...',
      img1: [],
      img2: [],
      searchShop: '',
      shopLists: [],
      geoCode: [],
      loading: false,
      loading1: false,
    })

    const areaProps = reactive({
      label: 'name',
      value: 'code',
      children: 'children',
      checkStrictly: true,
      multiple: true,
    })
    //获取区域数据
    const getAreas = () => {
      const getTreeData = data => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            data[i].children = undefined
          } else {
            getTreeData(data[i].children)
          }
        }
        return data
      }
      commonApi.getNewArea().then(res => {
        form.shopLists = getTreeData(res.data)
      })
    }

    const handleChangeMateria = value => {
      if (!value) return
      const selectObj = materialOptions.value.find(item => item.value == value)
      if (!Reflect.has(props.specRowMsg, 'code')) {
        props.specRowMsg.name = selectObj.materielName
        props.specRowMsg.spec = selectObj.specification
        props.specRowMsg.unit = selectObj.assistMeasurementUnitName
      }
      props.specRowMsg.sellingMaterials = selectObj.sellingMaterials
      props.specRowMsg.isStock = true
    }
    const casRef = ref()

    const checked1 = ref(false)

    const visible = computed(() => {
      return props.dialogVisible
    })
    onMounted(() => {
      getAreas()
      queryMaterielBindingInfo()
    })

    //关联物料
    const materialOptions = ref([])
    const queryMaterielBindingInfo = async () => {
      let res = await API.queryMaterielBindingInfo({
        skuCode: props.specRowMsg.code,
        materielCode: props.specRowMsg.materielCode,
      })
      materialOptions.value = res.data.map(item => {
        return {
          value: item.materielCode,
          label: item.materielCode + '_' + item.sellingMaterials + '_' + item.materielName + '_' + item.specification,

          ...item,
        }
      })
    }
    /**
     * 改变门店
     * @param {*} data
     * isAll:是否全选 true全选 false全不选：需要将之前加的数据删除
     */
    const changeStore = ({ isAll, data = [], isOneClick }) => {
      if (isOneClick) {
        props.specRowMsg.storeCodeList = data
        return
      }
      if (isAll) {
        data.forEach(item => {
          let index = props.specRowMsg.storeCodeList.findIndex(i => i === item)
          if (index === -1) {
            props.specRowMsg.storeCodeList.push(item)
          }
        })
      } else {
        data.forEach(item => {
          let index = props.specRowMsg.storeCodeList.findIndex(i => i === item)
          props.specRowMsg.storeCodeList.splice(index, 1)
        })
      }
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const casRef1 = ref()
    //  获取门店

    const changeImage1 = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img1.push(file)
      props.specRowMsg.picLay = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.picLay = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }
    const changeImage2 = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img2.push(file)
      props.specRowMsg.pic = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.pic = res.res.requestUrls[0]
        })
        .finally(err => {
          form.loading = false
        })
    }

    const changeStock = async () => {
      if ('code' in props.specRowMsg && !props.specRowMsg.isStock) {
        let res = await API.checkChargeToInventory({ skuCode: props.specRowMsg.code })
        if (res.code != 200) {
          ElMessage.error(res.msg)
          props.specRowMsg.isStock = !props.specRowMsg.isStock
        }
      }
    }

    const add = params => {
      API.saveSkuOne(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateSkuByCode(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const checkMiniNum = (rule, value, callback) => {
      if (!props.specRowMsg.minimum) {
        callback()
      } else if (!/^[0-9]{1,2}(\.[0-9]{0,2})?$/.test(toRaw(props.specRowMsg.minimum))) {
        return callback(new Error('最低毛利率是数字，且最多保留两位'))
      } else {
        callback()
      }
    }
    const checkprice = (rule, value, callback) => {
      if (!props.specRowMsg.price1 || !props.specRowMsg.price2) {
        return callback(new Error('指导价必填'))
      }

      if (isNaN(toRaw(props.specRowMsg.price1)) || isNaN(toRaw(props.specRowMsg.price1))) {
        return callback(new Error('指导价必须是数字'))
      }
      if (parseInt(toRaw(props.specRowMsg.price1)) > parseInt(toRaw(props.specRowMsg.price2))) {
        return callback(new Error('指导价后面必须大于前面'))
      } else {
        callback()
      }
    }
    const strlen = str => {
      if (!str) return 0

      let len = 0
      for (let i = 0; i < str.length; i++) {
        const code = str.codePointAt(i)

        if (!code) continue

        // ASCII字符占1个字节
        if (code <= 0x007f) {
          len += 1
        }
        // 汉字和其他Unicode字符占2个字节
        else if (code <= 0xffff) {
          len += 2
        }
        // emoji和其他扩展字符占4个字节
        else {
          len += 2
          i++ // Skip one loop since emoji uses two UTF-16 chars
        }
      }
      console.log(len)

      return len
    }
    const checkName = (rule, value, callback) => {
      if (strlen(value) > 40) {
        callback(new Error('单品名称不能超过20个字'))
      } else {
        callback()
      }
    }
    const checkDescription = (rule, value, callback) => {
      if (strlen(value) > 26) {
        callback(new Error('产品介绍不能超过13个字'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      price2: [{ required: true, trigger: 'blur', validator: checkprice }],
      picLay: [{ required: true, message: '请上传进货图片', trigger: 'blur' }],
      pic: [{ required: true, message: '请上传销售图片', trigger: 'blur' }],
      inName: [{ required: true, message: '请填写进货名称', trigger: 'blur' }],
      name: [{ trigger: 'blur', validator: checkName }],
      description: [{ trigger: 'blur', validator: checkDescription }],
      unit: [{ required: true, message: '请选择最小单位', trigger: 'blur' }],
      miniNumber: [{ required: true, message: '请填写起售数量', trigger: 'blur' }],
      unitLay: [{ required: true, message: '请选择进货单位', trigger: 'blur' }],
      caseSpec: [{ required: true, message: '请填写进货比率', trigger: 'blur' }],
      price2: [{ required: true, trigger: 'blur', validator: checkprice }],
      minimum: [{ required: false, trigger: 'blur', validator: checkMiniNum }],
      channel: [{ required: true, message: '请选择投放渠道', trigger: 'blur' }],
      isSales: [{ required: true, message: '请选择下发将来门店方式', trigger: 'blur' }],
      storeCodeList: [{ required: true, message: '请选择商品分配的门店', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            ...props.specRowMsg,
            price: toRaw(props.specRowMsg.price1) + '~' + toRaw(props.specRowMsg.price2),
            type: 1,
            isStock: props.specRowMsg.isStock ? 1 : 0,
            isMinConsume: props.specRowMsg.isMinConsume ? 1 : 0,
            isRecommend: props.specRowMsg.isRecommend ? 1 : 0,
            channel: props.specRowMsg.channel.join(','),
            isSalesRegion: casRef.value.getCheckedNodes().map(item => item.data.code),
            storeCodeList: Array.from(new Set(props.specRowMsg.storeCodeList)),
            createBy: JSON.parse(localStorage.getItem('vuex')).user.username,
          }
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      checked1,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage1,
      changeImage2,
      rules,
      elForm,
      areaProps,
      casRef,
      casRef1,
      changeStock,
      changeStore,
      materialOptions,
      handleChangeMateria,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
</style>
